<template>
  <div>
    <b-modal
      v-model="isModalShow"
      v-bind:title="title"
      @hide="setShowModalDisplayNote(false)"
    >
      <div class="form-group">
        <label>รายละเอียดเพิ่มเติม</label>
        <b-form-textarea
          id="textarea"
          v-model="orderSelected.note"
          placeholder=""
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant=""
            class="float-right bg-color-primary text-white"
            @click="onSaveNote()"
          >
            แก้ไข
          </b-button>
          <b-button
            variant="secondary"
            class="float-right mr-1"
            @click="setShowModalDisplayNote(false)"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "AddTracking",
  props: {
    orderSelected: Object,
    isShow: {
      required: false,
      type: Boolean
    },
    onSaveNote: {
      required: false,
      type: Function
    }
  },
  computed: {
    isModalShow: {
      get: function() {
        return this.isShow;
      },
      set: function() {}
    },
    title: {
      get: function() {
        return "หมายเลขคำสั่งซื้อ: " + this.orderSelected.invoiceNo;
      },
      set: function() {}
    }
  },
  methods: {
    setShowModalDisplayNote(value) {
      this.$emit("setShowModalDisplayNote", value);
    }
  }
};
</script>

<style scoped></style>
