<template>
  <div>
    <b-modal
      v-model="isModalShow"
      v-bind:title="title"
      @hide="setShowModalUpdateTracking(false)"
      hide-footer
    >
      <div class="form-group">
        <label>เลขพัสดุ</label>
        <small
          class="text-danger"
          v-if="isValidate && !this.trackingObj.trackingNo.text"
        >
          กรุณาใส่เลขพัสดุ
        </small>
        <input
          v-model="trackingObj.trackingNo.text"
          :state="trackingState"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>รายละเอียดเพิ่มเติม</label>
        <b-form-textarea
          id="textarea"
          v-model="trackingObj.note"
          placeholder=""
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>
      <div class="d-flex justify-content-between mt-3">
        <div class="d-flex mt-1">
          <b-form-checkbox
            v-model="selectedUpdateCustomer"
            name="selectedUpdateCustomer"
            id="selectedUpdateCustomer"
          >
          </b-form-checkbox>
          <label class="form-check-label" for="selectedUpdateCustomer"
            >อัพเดทลูกค้าทันที</label
          >
        </div>
        <div>
          <button
            @click="setShowModalUpdateTracking(false)"
            class="btn btn-secondary"
          >
            ยกเลิก
          </button>
          <button
            @click="onUpdateTrackingNo()"
            class="ml-2 btn bg-color-primary text-white"
          >
            ตกลง
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "UpdateTracking",
  props: {
    orderSelected: Object,
    isShow: {
      required: false,
      type: Boolean
    },
    updateTrackingNo: Function
  },
  data: function() {
    return {
      trackingState: true,
      note: "",
      invoiceNo: "",
      isValidate: false,
      isModalShow: this.isShow,
      title: this.orderSelected.invoiceNo,
      trackingObj: this.orderSelected,
      selectedUpdateCustomer: false
    };
  },
  computed: {},
  methods: {
    setShowModalUpdateTracking(value) {
      this.$emit("setShowModalUpdateTracking", value);
      this.trackingObj = {};
    },
    onUpdateTrackingNo() {
      this.isValidate = true;
      if (this.trackingObj.trackingNo.text) {
        this.isValidate = false;
        this.updateTrackingNo(
          this.orderSelected.id,
          this.trackingObj.trackingNo.text,
          this.trackingObj.note,
          this.selectedUpdateCustomer
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
