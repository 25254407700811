<template>
  <div>
    <b-modal
      hide-footer
      hide-header
      v-model="showModal"
      size="md"
      ref="modal-message"
    >
      <div class="text-center">
        <div class="textc-primary p-4">{{ message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    message: String
  },
  data() {
    return {
      showModal: false
    };
  },
  created() {},
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
