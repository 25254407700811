<template>
  <div>
    <div
      v-if="transaction.paymentMethodId == 0"
      class="bg-color-secondary rounded shadow-sm p-5 text-color-black"
    >
      <span>-</span>
    </div>
    <div
      class="p-3 bg-color-secondary rounded shadow-sm text-color-black"
      v-else
    >
      <div class="d-flex">
        <div>ช่องทางการชำระเงิน</div>
        <div class="ml-auto">
          <span>{{ transaction.paymentName }}</span>
        </div>
      </div>
      <template v-if="transaction.paymentMethodId !== 1">
        <div class="d-flex">
          <div>หมายเลขอ้างอิง</div>
          <div class="ml-auto">
            <span>{{ transaction.paymentReference }}</span>
          </div>
        </div>
      </template>
      <template
        v-els-if="transaction.paymentMethodId == 1 && paymentTransferSlip"
      >
        <div class="d-flex">
          <div>ธนาคารปลายทาง</div>
          <div class="ml-auto">
            <span v-if="bannk">{{ bannk.bankName }}</span>
            <span v-else> -</span>
          </div>
        </div>

        <div class="d-flex">
          <div>วันที่/เวลาการชำระเงิน</div>
          <div class="ml-auto">
            <span
              v-if="
                paymentTransferSlip.transferDate ||
                  paymentTransferSlip.transferDate != '0001-01-01T00:00:00'
              "
            >
              {{
                new Date(paymentTransferSlip.transferDate) | moment($formatDate)
              }}
              {{ paymentTransferSlip.transferTime.substring(0, 8) }}</span
            >
            <span v-else>-</span>
          </div>
        </div>
        <div class="d-flex">
          <div class="mb-2">
            <span>หลักฐานการชำระเงิน</span>
          </div>

          <div
            v-if="paymentTransferSlip.imageList.length == 0"
            class="ml-auto d-flex justify-content-end"
          >
            -
          </div>
        </div>
        <div v-if="paymentTransferSlip.imageList.length > 0">
          <div class="">
            <div
              class="slip-image my-2"
              v-bind:style="{
                'background-image':
                  'url(' + paymentTransferSlip.imageList[0].imageUrl + ')'
              }"
            ></div>
          </div>
        </div>
        <div
          v-if="paymentTransferSlip.imageList.length > 1"
          class="d-flex justify-content-end"
        >
          <span>
            <u class="ml-2 pointer" @click="setShowModalPayment(true)"
              >มีรูปอีก
              {{ paymentTransferSlip.imageList.length - 1 }}
              รูป</u
            >
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    transaction: {
      required: true,
      type: Object
    },
    setShowModalPayment: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      paymentTransferSlip: {
        amount: 0,
        bankAccountId: 0,
        imageList: [],
        transferDate: null,
        transferTime: null
      },
      banks: []
    };
  },
  created: async function() {
    if (this.transaction.paymentMethodId == 1) {
      await this.getBanks();
    }
    await this.getUploadSlip();
    this.$moment.locale("th");
  },
  computed: {
    bannk: function() {
      return this.banks.find(el => {
        return el.id == this.paymentTransferSlip.bankAccountId;
      });
    },

    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      mainColor: "getBrandColor"
    })
  },
  methods: {
    getBanks() {
      this.$axios
        .get(this.$baseUrl + "/api/payment/bankaccount", {
          params: { transactionId: this.transaction.id }
        })
        .then(response => {
          if (response.data.result === 1) {
            this.banks = response.data.detail;
          }
        });
    },
    getUploadSlip() {
      this.$axios
        .get(
          this.$baseUrl +
            "/api/transaction/" +
            this.transaction.id +
            "/transferSlip"
        )
        .then(response => {
          if (response.data.result === 1) {
            this.paymentTransferSlip = response.data.detail;
            if (this.transaction.paymentMethodId !== 1) {
              this.paymentTransferSlip.imageList = [];
            }
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.slip-image {
  width: auto;
  height: 350px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}
</style>
