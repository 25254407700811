<template>
  <div>
    <b-modal
      hide-footer
      title="ค้นหาลูกค้า"
      v-model="showModal"
      size="md"
      ref="modal-message"
    >
      <div class="">
        <b-input-group>
          <b-form-input
            ref="keywordInput"
            v-model="filter.search"
            @keyup.enter="onSearchCustomer()"
            placeholder="ค้นหาโดยชื่อ"
            style="border-radius:50px 0 0 50px"
          ></b-form-input>
          <template v-slot:append>
            <button
              class="btn bg-dark text-white pill-right"
              @click="getCustomer(false)"
            >
              ค้นหา
            </button>
          </template>
        </b-input-group>
      </div>
      <div>
        <template v-if="customerList.length == 0">
          <div class="p-5 text-center">
            ไม่พบข้อมูล
          </div>
        </template>
        <template v-else>
          <div class="customer-panel mt-4 pointer">
            <div
              v-for="(item, index) in customerList"
              :key="index"
              class="d-flex p-2 customer-item"
              @click="onSelectedCustomer(item)"
            >
              <div class="my-auto">
                <label class="panel-image m-0 mt-1">
                  <div
                    v-if="item.profilePictureUrl"
                    class="profile-img"
                    v-bind:style="{
                      backgroundImage: 'url(' + item.profilePictureUrl + ')'
                    }"
                  ></div>
                  <div
                    v-else
                    class="profile-img"
                    v-bind:style="{
                      backgroundImage: 'url(' + profile + ')'
                    }"
                  ></div>
                </label>
              </div>
              <div class="ml-4 my-auto">
                <span>
                  {{ item.socialName }}
                </span>
              </div>
            </div>
            <infinite-loading
              spinner="spiral"
              @infinite="scrollMethod"
              ref="infiniteLoading"
            >
              <div slot="spinner" class="">
                <img
                  src="@/assets/images/loading.svg"
                  alt="loading"
                  class="loading-icon"
                />
              </div>
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>
        </template>
      </div>
    </b-modal>
  </div>
</template>

<script>
import profile from "@/assets/images/userMenu-profile.svg";
import { mapGetters } from "vuex";
export default {
  props: {
    message: String,
    saleChannel: [Number, String]
  },
  data() {
    return {
      showModal: false,
      customerList: [],
      profile: profile,
      filter: {
        search: "",
        perPage: 10,
        pageNo: 1,
        socialSourceId: []
      }
    };
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandID: "getBrand",
      mainColor: "getBrandColor"
    })
  },
  created: async function() {},
  methods: {
    onSelectedCustomer(item) {
      this.$emit("onSelectedCustomer", item);
    },
    onSearchCustomer() {
      this.getCustomer();
    },
    getCustomer: async function(scrollFlag, $state) {
      if (!scrollFlag) this.filter.pageNo = 1;
      await this.$axios
        .post(
          `${this.$baseUrl}/api/usersocial/${this.brandID}/list`,
          this.filter
        )
        .then(response => {
          if (scrollFlag) {
            if (response.data.detail.userProfileList.length == 0) {
              if ($state) $state.complete();
            } else {
              this.customerList = [
                ...this.customerList,
                ...response.data.detail.userProfileList
              ];

              if ($state) $state.loaded();
            }
          } else {
            this.customerList = response.data.detail.userProfileList;
            if ($state) $state.loaded();
          }
        });
    },
    show() {
      this.showModal = true;
      if (this.saleChannel == 1 || this.saleChannel == 2) {
        this.filter.socialSourceId = [2];
      }
      if (this.saleChannel == 3) {
        this.filter.socialSourceId = [1];
      }
      this.getCustomer();
    },
    hide() {
      this.showModal = false;
    },
    scrollMethod($state) {
      setTimeout(() => {
        this.filter.pageNo++;
        this.getCustomer(true, $state);
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
.customer-panel {
  overflow: auto;
  max-height: calc(100vh - 200px);
}
.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}

.panel-image {
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
}

.customer-item:hover {
  background-color: rgb(229, 239, 247);
  border-radius: 10px;
}

@media (max-width: 767.98px) {
  .customer-panel {
    overflow: auto;
    max-height: calc(100vh - 200px);
  }
}
</style>
